
  import {shuffleArray} from '@/helper/ArrayHelper';
  import {prepareImageUrl} from '@/helper/ImageHelper';
  import ScreenSize from '@/mixins/ScreenSize';
  import Carousel from '@/mixins/Carousel';

  export default {
    name: 'SectionOrganizationCarousel',
    mixins: [ScreenSize, Carousel],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    serverCacheKey: function (props) {
      return 'section-organization-carousel-' + props.section.id;
    },
    data: () => ({
      sliderElements: [],
      model: null,
      slides: [],
    }),
    computed: {
      numberOfElements() {
        if (
          this.$vuetify.breakpoint.width > 960 &&
          this.$vuetify.breakpoint.width <= 1330
        ) {
          return 'six_6';
        } else {
          return 'seven_7';
        }
      },
      numberOfColumns() {
        const numberOfElements = this.getNumberOfElementsInSlide(
          this.numberOfElements
        );
        let colNumber = 0;
        if (numberOfElements === '2' && this.isDesktopOrTablet) {
          colNumber = 6;
        } else if (numberOfElements === '4' && this.isDesktopOrTablet) {
          colNumber = 3;
        } else if (numberOfElements === '3' && this.isDesktopOrTablet) {
          colNumber = 4;
        } else if (numberOfElements === '6' && this.isDesktopOrTablet) {
          colNumber = 2;
        }
        return colNumber;
      },
      elementNumber() {
        const elementsPerSlide = this.getNumberOfElementsInSlide(
          this.numberOfElements
        );
        if (this.$vuetify.breakpoint.width <= 505) {
          return 2;
        } else if (
          this.$vuetify.breakpoint.width > 505 &&
          this.$vuetify.breakpoint.width <= 610
        ) {
          return 3;
        } else if (
          this.$vuetify.breakpoint.width > 610 &&
          this.$vuetify.breakpoint.width <= 960
        ) {
          return 4;
        } else {
          return elementsPerSlide;
        }
      },
    },
    watch: {
      '$vuetify.breakpoint.width'() {
        this.setSlides();
      },
    },
    created() {
      // prepare carousel items
      if (this.section.items) {
        this.section.items.forEach((item) => {
          if (item.logo !== null) {
            this.sliderElements.push({
              text: item.title,
              image: prepareImageUrl(item.logo.url),
              link: item.slug
                ? this.localePath({
                    name: 'organizations-slug',
                    params: {slug: item.slug},
                  })
                : '',
            });
          }
        });

        this.sliderElements = shuffleArray(this.sliderElements);
      }
    },
    mounted() {
      this.setSlides();
    },
    methods: {
      getNumberOfElementsInSlide() {
        const number = this.numberOfElements.split('_');
        if (number.length > 1) {
          return number[1];
        }
      },
    },
  };
